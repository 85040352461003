import cn from "@/utils/cn";
import React from "react";

const FeatureMatrixComponent = () => {
  const titleClassName =
    "text-[12px] md:text-[12px] xl:text-[20px] leading-[20.5px] md:leading-[20.5px] xl:leading-[27px] font-semibold ";
  const commonTextClassName =
    "text-[9px] md:text-[10.8px] xl:text-[14px] leading-[18px] md:leading-[18px] xl:leading-[24px]";

  const data = [
    {
      section: "Audit and Reporting Tool",
      items: [
        {
          name: "Email Audits",
          basic: "present",
          standard: "present",
          premium: "present",
        },
        {
          name: "Report Templates",
          basic: "present",
          standard: "present",
          premium: "present",
        },
        {
          name: "Notifications",
          basic: "present",
          standard: "present",
          premium: "present",
        },
        {
          name: "PDF Report Export and Share",
          basic: "present",
          standard: "present",
          premium: "present",
        },
        {
          name: "Scheduled Reports",
          basic: "present",
          standard: "present",
          premium: "present",
        },
        {
          name: "Industry Benchmarks",
          basic: "present",
          standard: "present",
          premium: "present",
        },
        {
          name: "Status Reports and Issues",
          basic: "present",
          standard: "present",
          premium: "present",
        },
        {
          name: "Suggestions",
          basic: "present",
          standard: "present",
          premium: "present",
        },
        {
          name: "AI Assistance",
          basic: "present",
          standard: "present",
          premium: "present",
        },
        {
          name: "Custom Report Editor",
          basic: "absent",
          standard: "present",
          premium: "present",
        },
      ],
    },
    {
      section: "Dashboard Builder Tool",
      items: [
        {
          name: "Dashboard Templates",
          basic: "present",
          standard: "present",
          premium: "present",
        },
        {
          name: "Filtering and Sorting Options",
          basic: "present",
          standard: "present",
          premium: "present",
        },
        {
          name: "White-label Personalization",
          basic: "absent",
          standard: "present",
          premium: "present",
        },
        {
          name: "Custom Dashboard Editor",
          basic: "absent",
          standard: "present",
          premium: "present",
        },
      ],
    },
    {
      section: "Team Collaboration",
      items: [
        {
          name: "Accounts",
          basic: "1",
          standard: "3",
          premium: "Unlimited",
        },
        {
          name: "Users",
          basic: "1",
          standard: "10",
          premium: "Unlimited",
        },
        {
          name: "Team Collaboration",
          basic: "absent",
          standard: "present",
          premium: "present",
        },
        {
          name: "Connected Account Access",
          basic: "absent",
          standard: "present",
          premium: "present",
        },
      ],
    },
    {
      section: "Support",
      items: [
        {
          name: "Email Support",
          basic: "present",
          standard: "present",
          premium: "present",
        },
        {
          name: "Knowledge Base",
          basic: "present",
          standard: "present",
          premium: "present",
        },
      ],
    },
    {
      section: "Embeddable Audit Tool",
      items: [
        {
          name: "Embedded Audit Forms",
          basic: "absent",
          standard: "absent",
          premium: "present",
        },
        {
          name: "Generate Leads",
          basic: "absent",
          standard: "absent",
          premium: "present",
        },
        {
          name: "Call To Action Buttons",
          basic: "absent",
          standard: "absent",
          premium: "present",
        },
        {
          name: "Leads Filtering and Sorting Options",
          basic: "absent",
          standard: "absent",
          premium: "present",
        },
      ],
    },
  ];

  return (
    <div className="mx-auto max-w-[1120px] px-[39px] py-[78px] text-[#444A6D] md:px-[36px] lg:px-20 xl:px-0">
      <div className="w-full rounded-[20px] border-[0.75px] border-[#EDF2F6] pb-[23px] pt-0.5">
        <table className="w-full rounded-[20px]">
          <thead className="">
            <tr className="text-[8px] text-[#444A6D] md:text-[12px] xl:text-[20px] ">
              <th
                className={cn(
                  "w-2/3 md:px-[20px] py-[23.25px] xl:px-[27px] xl:py-[31px] px-[14px] text-left text-kd-lte-blue font-semibold"
                  // titleClassName
                )}
              >
                Whats under the hood
              </th>
              <th className="border-l border-[#EDF2F6] px-[14px] py-[23.25px] text-center font-semibold md:px-[20px] xl:px-[27px] xl:py-[31px]">
                Basic
              </th>
              <th className="border-l border-[#EDF2F6] px-[14px] py-[23.25px] text-center font-semibold md:px-[20px] xl:px-[27px] xl:py-[31px]">
                Standard
              </th>
              <th className="border-l border-[#EDF2F6] px-[14px] py-[23.25px] text-center font-semibold md:px-[20px] xl:px-[27px] xl:py-[31px]">
                Premium
              </th>
            </tr>
          </thead>
          <tbody className={cn("", commonTextClassName)}>
            {data.map((section, index) => (
              <React.Fragment key={index}>
                <tr className="bg-[#FAFBFC]">
                  <td className="border-l border-[#EDF2F6] px-[14px] py-2 font-semibold md:px-[20px] xl:px-[27px]">
                    {section.section}
                  </td>
                  <td className="border-l border-[#EDF2F6] px-[14px] md:px-[20px] xl:px-[27px]"></td>
                  <td className="border-l border-[#EDF2F6] px-[14px] md:px-[20px] xl:px-[27px]"></td>
                  <td className="border-l border-[#EDF2F6] px-[14px] md:px-[20px] xl:px-[27px]"></td>
                </tr>
                {section.items.map((item, itemIndex) => (
                  <tr key={itemIndex} className="">
                    <td className=" border-l border-[#EDF2F6] px-[14px] py-[7px] md:px-[20px] xl:px-[27px]">
                      {item.name}
                    </td>
                    <td className="border-l border-[#EDF2F6] px-[14px] py-[7px] text-center text-[10.5px] font-medium text-kd-lte-blue md:px-[20px] xl:px-[27px] xl:text-[14px]">
                      {item.basic == "present" ? (
                        <Tick />
                      ) : item.basic == "absent" ? (
                        <Cross />
                      ) : (
                        item.basic
                      )}
                    </td>
                    <td className="border-l border-[#EDF2F6] px-[14px] py-[7px] text-center text-[10.5px] font-medium text-kd-lte-blue md:px-[20px] xl:px-[27px] xl:text-[14px]">
                      {item.standard == "present" ? (
                        <Tick />
                      ) : item.standard == "absent" ? (
                        <Cross />
                      ) : (
                        item.standard
                      )}
                    </td>
                    <td className="border-l border-[#EDF2F6] px-[14px] py-[7px] text-center text-[10.5px] font-medium text-kd-lte-blue md:px-[20px] xl:px-[27px] xl:text-[14px]">
                      {item.premium == "present" ? (
                        <Tick />
                      ) : item.premium == "absent" ? (
                        <Cross />
                      ) : (
                        item.premium
                      )}
                    </td>
                  </tr>
                ))}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default FeatureMatrixComponent;

// TODO: replace with react-icons
const Tick = ({ className = "" }) => {
  return (
    <div className={cn("flex justify-center items-center", className)}>
      <svg
        className="flex h-[12px] w-[12px] items-center justify-center rounded bg-kd-lte-blue p-[3px] xl:h-[16px] xl:w-[16px] xl:rounded-md xl:p-1"
        width="8"
        height="6"
        viewBox="0 0 8 6"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.80618 1.13951L3.13951 5.80618C3.07758 5.86826 3.00401 5.91752 2.92302 5.95113C2.84203 5.98473 2.7552 6.00203 2.66751 6.00203C2.57982 6.00203 2.49299 5.98473 2.412 5.95113C2.331 5.91752 2.25744 5.86826 2.19551 5.80618L0.195509 3.80618C0.133525 3.74419 0.0843565 3.67061 0.0508111 3.58962C0.0172656 3.50863 0 3.42183 0 3.33418C0 3.24652 0.0172656 3.15972 0.0508111 3.07873C0.0843565 2.99775 0.133525 2.92416 0.195509 2.86218C0.320691 2.73699 0.490475 2.66667 0.667509 2.66667C0.755168 2.66667 0.841968 2.68393 0.922954 2.71748C1.00394 2.75102 1.07753 2.80019 1.13951 2.86218L2.66751 4.39151L6.86218 0.195509C6.98736 0.0703268 7.15714 -4.56918e-09 7.33418 0C7.51121 4.56918e-09 7.68099 0.0703268 7.80618 0.195509C7.93136 0.320691 8.00168 0.490475 8.00168 0.667509C8.00168 0.844543 7.93136 1.01433 7.80618 1.13951Z"
          fill="white"
        />
      </svg>
    </div>
  );
};

const Cross = ({ className = "" }) => {
  return (
    <div className={cn("flex justify-center items-center", className)}>
      <svg
        className="flex h-[12px] w-[12px] items-center justify-center rounded bg-[#96A5B8] p-[3px] xl:h-[16px] xl:w-[16px] xl:rounded-md xl:p-1"
        width="8"
        height="8"
        viewBox="0 0 8 8"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.33398 1.28784L6.59151 6.50043"
          stroke="white"
          strokeWidth="1.41451"
          strokeLinecap="round"
        />
        <path
          d="M1.33398 6.50073L6.59151 1.28814"
          stroke="white"
          strokeWidth="1.41451"
          strokeLinecap="round"
        />
      </svg>
    </div>
  );
};
