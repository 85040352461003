"use client";
import { useState } from "react";
import { PrismicRichText } from "@prismicio/react";
import BillingToggle from "@/slices/Pricing/_components/BillingToggle";
import TextComponent from "./TextComponent";
import cn from "@/utils/cn";
import { HiOutlineChevronRight } from "react-icons/hi2";
import Button from "@/components/buttons";

type Step = "selection" | "payment" | "confirmation";

export interface PricingPlanItem {
  title: any;
  monthly_price: { text: string }[];
  annual_price: { text: string }[];
  monthly_text: any;
  annually_text: any;
  subtitle: any;
  description: any;
}

interface PricingPlansProps {
  items: PricingPlanItem[];
  onSelectPlan: (planName: string) => void;
  showFeatureMatrix?: boolean;
  setShowFeatureMatrix?: any;
}

export function PricingPlans({
  items,
  onSelectPlan,
  showFeatureMatrix,
  setShowFeatureMatrix,
}: PricingPlansProps) {
  const [billing, setBilling] = useState<"monthly" | "annually">("annually");
  const [step, setStep] = useState<Step>("selection");

  return (
    <div className="max-w-6xls mx-auto px-4 py-10">
      {/* Header and Billing Switch */}
      <div className="mb-10 flex flex-col md:flex-row md:items-center md:justify-between">
        <nav className="my-4">
          <div className={cn("flex items-center text-sm text-gray-600")}>
            {(["selection", "payment", "confirmation"] as Step[]).map(
              (currentStep, index) => (
                <div key={currentStep} className="flex items-center">
                  <TextComponent
                    type="caption"
                    className={
                      step === currentStep ? "font-semibold text-[#336DFF]" : ""
                    }
                  >
                    {currentStep.charAt(0).toUpperCase() + currentStep.slice(1)}
                  </TextComponent>
                  {index < 2 && (
                    <HiOutlineChevronRight className="mx-2 size-[18px] text-[#979797]" />
                  )}
                </div>
              ),
            )}
          </div>
        </nav>
        <BillingToggle
          isMonthly={billing === "monthly"}
          setIsMonthly={(value) => setBilling(value ? "monthly" : "annually")}
        />
      </div>

      {/* Pricing Cards */}

      <div className="mt-10 flex flex-col items-center justify-between md:flex-row lg:space-y-0">
        {/* Special introductory card */}
        <div
          className={cn(
            "flex flex-col justify-between rounded-[15px] xl:rounded-[20px] relative bg-transparent p-6 ",
            "xl:w-[300px] xl:h-[396px] w-[225px] h-[300px]",
            "xl:flex hidden",
          )}
        >
          <div>
            <TextComponent
              type="h3"
              className="text-[23px] font-semibold text-[#05004E]"
            >
              Pricing Plans for Every Budget
            </TextComponent>

            <TextComponent
              type="caption"
              className="mt-4 text-sm text-kd-gray-text"
            >
              Get ready to unlock all the{" "}
              <span className="font-semibold">campaign enhancing features</span>{" "}
              you need to escalate your email strategies, measure performance,
              and deliver Month-on-Month, email campaign growth. No surprises,
              just transparent pricing for real results.
            </TextComponent>
          </div>
          <Button
            variant="secondary"
            onClick={() => {
              setShowFeatureMatrix(!showFeatureMatrix);
            }}
          >
            {showFeatureMatrix ? "Hide features" : "Show features"}
          </Button>
        </div>

        {/* Existing cards */}
        {items.map((item, index) => (
          <div
            key={index}
            className={cn(
              "flex flex-col justify-between rounded-[15px] xl:rounded-[20px] border relative bg-white p-6 shadow-lg",
              "xl:w-[300px] xl:h-[396px] w-[225px] h-[300px]",
              index === 1
                ? "border-kd-lte-blue border-t-0 rounded-t-none"
                : "border-[#EDF2F6]",
            )}
          >
            {index === 1 && (
              <div className="absolute -top-7 right-0 flex h-10 w-full items-center justify-center rounded-t-[20px] bg-kd-lte-blue ring-2 ring-kd-lte-blue">
                <TextComponent
                  type="body"
                  className="text-center font-semibold text-white"
                >
                  Best Value
                </TextComponent>
              </div>
            )}
            <div>
              <TextComponent type="h3" className="text-[#05004E]">
                <PrismicRichText field={item.title} />
              </TextComponent>

              <div className="mt-[28px] flex items-baseline text-[#336DFF] xl:mt-[38px]">
                <TextComponent
                  type="h2"
                  className="text-[35px] font-semibold leading-[24px] xl:text-[40px] xl:leading-[32px]"
                >
                  <sup className="text-[20px] xl:text-[24px]">$</sup>
                  {billing === "monthly"
                    ? item.monthly_price[0].text
                    : item.annual_price[0].text}
                </TextComponent>
                <TextComponent
                  type="body"
                  className=" ml-1 text-left font-semibold"
                >
                  / month
                </TextComponent>
              </div>
              <TextComponent
                type="body"
                className="mt-2 text-sm font-medium leading-[24px] text-kd-lte-blue xl:leading-[32px]"
              >
                <PrismicRichText
                  field={
                    billing === "monthly"
                      ? item.monthly_text
                      : item.annually_text
                  }
                />
              </TextComponent>
              <div className="mt-4">
                <TextComponent
                  className="font-semibold text-black"
                  type="caption"
                >
                  <PrismicRichText field={item.subtitle} />
                </TextComponent>
                <TextComponent type="caption">
                  <PrismicRichText field={item.description} />
                </TextComponent>
              </div>
            </div>
            <Button
              onClick={() =>
                onSelectPlan(
                  billing === "monthly"
                    ? item.monthly_price[0].text
                    : item.annual_price[0].text,
                )
              }
            >
              Upgrade
            </Button>
          </div>
        ))}
      </div>
    </div>
  );
}
