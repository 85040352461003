// app/components/PaymentForm.tsx
import { useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
  CardElement,
  Elements,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import axios from "axios";

interface PaymentFormProps {
  plan: string;
  onSuccess: (details: { orderNumber: string; email: string }) => void;
}

function PaymentFormContent({ plan, onSuccess }: PaymentFormProps) {
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [email, setEmail] = useState("");
  const [cardHolder, setCardHolder] = useState("");
  const [agreed, setAgreed] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!stripe || !elements) {
      console.error("Stripe or Elements not initialized");
      return;
    }

    setLoading(true);
    setError(null);

    try {
      console.log("Creating payment method...");
      const { error: cardError, paymentMethod } =
        await stripe.createPaymentMethod({
          type: "card",
          card: elements.getElement(CardElement)!,
          billing_details: {
            name: cardHolder,
            email: email,
          },
        });

      if (cardError) {
        console.error("Card error:", cardError);
        setError(cardError.message || "Payment method creation failed");
        return;
      }

      // Create subscription
      console.log("Creating subscription...");
      const { data: subscriptionData } = await axios.post("/api/subscription", {
        paymentMethodId: paymentMethod.id,
        planName: plan,
      });

      // Confirm the payment
      if (subscriptionData.clientSecret) {
        console.log("Confirming card payment...");
        const { error: confirmError, paymentIntent } =
          await stripe.confirmCardPayment(subscriptionData.clientSecret);

        if (confirmError) {
          console.error("Payment confirmation error:", confirmError);
          throw new Error(confirmError.message);
        }

        if (paymentIntent && paymentIntent.status === "succeeded") {
          console.log("Payment confirmed successfully");
          // The webhook will handle updating the subscription status
          onSuccess({
            orderNumber: subscriptionData.orderNumber,
            email: email,
          });
        } else {
          throw new Error("Payment confirmation failed");
        }
      }
    } catch (err) {
      console.error("Subscription process failed:", err);
      if (axios.isAxiosError(err)) {
        setError(
          err.response?.data?.error || err.message || "Subscription failed",
        );
      } else {
        setError((err as Error).message || "Something went wrong");
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="mx-auto max-w-2xl rounded-lg border border-gray-200 bg-white p-6 shadow-sm">
      <form onSubmit={handleSubmit} className="space-y-6">
        {error && (
          <div className="rounded-lg bg-red-50 p-3 text-red-500">{error}</div>
        )}

        <div>
          <label className="mb-2 block text-sm font-medium text-gray-700">
            Email
          </label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="w-full rounded-lg border border-gray-300 px-4 py-2 focus:border-blue-500 focus:ring-2 focus:ring-blue-500"
            placeholder="your@email.com"
            required
          />
        </div>

        <div>
          <label className="mb-2 block text-sm font-medium text-gray-700">
            Card Information
          </label>
          <div className="rounded-lg border border-gray-300 p-4">
            <CardElement
              options={{
                style: {
                  base: {
                    fontSize: "16px",
                    color: "#424770",
                    "::placeholder": {
                      color: "#aab7c4",
                    },
                  },
                  invalid: {
                    color: "#9e2146",
                  },
                },
              }}
            />
          </div>
        </div>

        <div>
          <label className="mb-2 block text-sm font-medium text-gray-700">
            Cardholder Name
          </label>
          <input
            type="text"
            value={cardHolder}
            onChange={(e) => setCardHolder(e.target.value)}
            className="w-full rounded-lg border border-gray-300 px-4 py-2 focus:border-blue-500 focus:ring-2 focus:ring-blue-500"
            placeholder="Full Name on Card"
            required
          />
        </div>

        <div className="flex items-center space-x-2">
          <input
            type="checkbox"
            id="terms"
            className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
            checked={agreed}
            onChange={(e) => setAgreed(e.target.checked)}
            required
          />
          <label htmlFor="terms" className="text-sm text-gray-600">
            I agree to Email Audit Engine's Terms of Service and Privacy Policy
          </label>
        </div>

        <button
          type="submit"
          disabled={!stripe || loading || !agreed}
          className="w-full rounded-lg bg-blue-600 px-4 py-2 font-semibold text-white transition-colors hover:bg-blue-700 disabled:bg-gray-400"
        >
          {loading ? "Processing..." : "Continue"}
        </button>
      </form>
    </div>
  );
}

const stripePromise = loadStripe(
  process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY!,
);

export function PaymentForm(props: PaymentFormProps) {
  return (
    <Elements stripe={stripePromise}>
      <PaymentFormContent {...props} />
    </Elements>
  );
}
