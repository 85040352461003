"use client";

import { ConfirmationPage } from "@/app/_components/ConfirmationPayment";
import { PaymentForm } from "@/app/_components/PaymentForm";
import { PricingPlans } from "@/app/_components/PricingPlans";
import TextComponent from "@/app/_components/TextComponent";
import FeatureMatrixComponent from "@/slices/FeatureMatrix/feature-matrix";
import { createClient } from "@/prismicio";
import { useCallback, useEffect, useMemo, useState } from "react";
import { HiOutlineChevronRight } from "react-icons/hi2";
import cn from "@/utils/cn";

type Step = "selection" | "payment" | "confirmation";

interface OrderDetails {
  orderNumber: string;
  email: string;
}

const usePricingData = () => {
  const [data, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchPricing = async () => {
      try {
        const client = createClient();
        const content = await client.getSingle("pricing_page");
        const pricingSlice = content.data.slices.find(
          (slice: any) => slice.slice_type === "pricing",
        );
        setData(pricingSlice?.items || []);
      } catch (err) {
        setError("Failed to fetch pricing data.");
      } finally {
        setLoading(false);
      }
    };

    fetchPricing();
  }, []);

  return { data, loading, error };
};

export default function BillingModalContent() {
  const [step, setStep] = useState<Step>("selection");
  const [selectedPlan, setSelectedPlan] = useState<string>("");
  const [orderDetails, setOrderDetails] = useState<OrderDetails | null>(null);
  const [showFeatureMatrix, setShowFeatureMatrix] = useState(true);

  const { data: pricingItems, loading, error } = usePricingData();

  const handlePlanSelection = useCallback((plan: string) => {
    setSelectedPlan(plan);
    setStep("payment");
  }, []);

  const handlePaymentSuccess = useCallback((details: OrderDetails) => {
    setOrderDetails(details);
    setStep("confirmation");
  }, []);

  const stepTitle = useMemo(() => {
    switch (step) {
      case "selection":
        return "Upgrade Your Plan";
      case "payment":
        return "Payment";
      case "confirmation":
        return "Confirmation";
      default:
        return "";
    }
  }, [step]);

  if (loading) return <div className="py-20 text-center">Loading...</div>;
  if (error)
    return <div className="py-20 text-center text-red-500">{error}</div>;

  return (
    <main className="min-h-screen bg-gray-50 py-8">
      <div className="max-w-6xls mx-auto px-4">
        <TextComponent type="h2" className="capitalize text-kd-lte-blue">
          {stepTitle}
        </TextComponent>

        {step !== "selection" && (
          <nav className="my-4">
            <div className={cn("flex items-center text-sm text-gray-600")}>
              {(["selection", "payment", "confirmation"] as Step[]).map(
                (currentStep, index) => (
                  <div key={currentStep} className="flex items-center">
                    <TextComponent
                      type="caption"
                      className={
                        step === currentStep
                          ? "font-semibold text-[#336DFF]"
                          : ""
                      }
                    >
                      {currentStep.charAt(0).toUpperCase() +
                        currentStep.slice(1)}
                    </TextComponent>
                    {index < 2 && (
                      <HiOutlineChevronRight className="mx-2 size-[18px] text-[#979797]" />
                    )}
                  </div>
                ),
              )}
            </div>
          </nav>
        )}

        {step === "selection" && (
          <PricingPlans
            onSelectPlan={handlePlanSelection}
            items={pricingItems}
            showFeatureMatrix={showFeatureMatrix}
            setShowFeatureMatrix={setShowFeatureMatrix}
          />
        )}

        {step === "payment" && selectedPlan && (
          <section className="mx-auto max-w-2xl px-4">
            <PaymentForm plan={selectedPlan} onSuccess={handlePaymentSuccess} />
          </section>
        )}

        {step === "confirmation" && orderDetails && (
          <ConfirmationPage
            orderNumber={orderDetails.orderNumber}
            email={orderDetails.email}
          />
        )}

        {showFeatureMatrix && <FeatureMatrixComponent />}
      </div>
    </main>
  );
}
