// app/components/ConfirmationPage.tsx
interface ConfirmationProps {
  orderNumber: string;
  email: string;
}

export function ConfirmationPage({ orderNumber, email }: ConfirmationProps) {
  return (
    <div className="mx-auto w-full max-w-2xl px-4">
      <h1 className="text-3xl font-bold text-blue-600">Confirmation</h1>
      <div className="mt-4 text-green-500">Successfully activated!</div>

      <div className="mt-8 space-y-4 rounded-lg border border-gray-200 bg-white p-6 shadow-sm">
        <div className="flex justify-between border-b border-gray-100 py-2">
          <span className="text-gray-600">Order number</span>
          <span className="font-medium">{orderNumber}</span>
        </div>
        <div className="flex justify-between border-b border-gray-100 py-2">
          <span className="text-gray-600">Email</span>
          <span className="font-medium">{email}</span>
        </div>
      </div>

      <div className="mt-8">
        <p className="mb-6 text-gray-600">
          You are all set up to access the Engine! An email confirmation has
          been sent to {email}.
        </p>
        <button className="w-full rounded-lg bg-blue-600 px-4 py-2 font-semibold text-white transition-colors hover:bg-blue-700">
          Dashboard
        </button>
      </div>
    </div>
  );
}
